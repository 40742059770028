/**
 * Account login
 * @return {accountLogin~init}
 */
function accountLogin() {
  const c = {
    id: {
      hideRecoverPassword: 'hideRecoverPasswordBtn',
      recoverPassword: 'recoverPassword',
      sectionLogin: 'acLoginForm',
      sectionRecoverPassword: 'acRecoverPasswordForm',
    },
    url: {
      recover: '#recover',
    },
  };

  // Globals
  const $sectionLogin = document.getElementById(c.id.sectionLogin);
  const $sectionRecoverPassword = document.getElementById(c.id.sectionRecoverPassword);

  /**
   * Init
   */
  function init() {
    detectRecover();
    addEvents();
  }

  /**
   * Add events
   */
  function addEvents() {
    // Forgot password click
    __.addEvent({
      id: c.id.recoverPassword,
      event: 'click',
      fn: showRecoverPasswordForm,
    });

    // Go back click
    __.addEvent({
      id: c.id.hideRecoverPassword,
      event: 'click',
      fn: hideRecoverPasswordForm,
    });
  }

  /**
   * Show recover password form
   */
  function showRecoverPasswordForm() {
    __.hide($sectionLogin);
    __.show($sectionRecoverPassword);
  }

  /**
   * Hide recover password form
   */
  function hideRecoverPasswordForm() {
    __.hide($sectionRecoverPassword);
    __.show($sectionLogin);
  }

  /**
   * Detect recover
   */
  function detectRecover() {
    if (window.location.hash === c.url.recover) {
      showRecoverPasswordForm();
    }
  }

  return init();
}

export default accountLogin;
