/**
 * Account
 */

import accountLogin from '../lib/account-login';

const $login = document.getElementById('acLogin');

// Login
if ($login) {
  accountLogin();
}
